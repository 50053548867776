<template>
  <div class="page p-grid">
    <div class="p-col-12">
      <Breadcrumb :model="items"/>
    </div>
  </div>
  <div class="page p-grid p-mt-2">
    <div style="text-align: center" class="p-mt-4 p-col-12">
      <label style="font-size: 20px; font-weight: bold">{{ data.title }}</label>
    </div>
    <div class="p-p-12" v-html="data.content">
    </div>
    <div class="p-col-12">
      <div class="p-p-2">
        <label style="font-size: 18px; font-weight: bold">发布行业：</label>
        <label style="font-size: 18px; font-weight: bold">{{ data.industry }}</label>
      </div>
    </div>
    <div class="p-col-12">
      <div class="p-p-2 p-grid">
        <div class="p-col">
          <label style="font-size: 18px; font-weight: bold">发布时间：</label>
          <label style="font-size: 18px; font-weight: bold">{{ data.addTime }}</label>
        </div>
        <div class="p-col" v-if="data.gainful">
          <label style="font-size: 18px; font-weight: bold">报酬：</label>
          <label style="font-size: 18px; font-weight: bold">{{ data.gainful }}</label>
        </div>

      </div>
      <div v-if="data.state==0">
        <Button label="分配问题" @click="display=true"></Button>
      </div>
    </div>
    <Dialog style="width: 40%" v-model:visible="display">
      <div class="p-p-2">
        <div>选择专家</div>
        <MultiSelect v-model="List" :options="expertList" optionLabel="userName" option-value="id" placeholder="选择专家" />
        <Button @click="submit" label="确认"></Button>
      </div>
    </Dialog>
  </div>
  <Toast/>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      display: false,
      data: {},
      List:null,
      expertList:[],
      items: [
        {label: '消息推送', to: '/MessageList'},
        {label: '消息发送列表', to: '/MessageList'},
        {label: '消息详情', to: '/NewsDetails'}
      ]
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      let id = this.$route.query.id
      this.$http.get('/question/get?id=' + id).then((res) => {
        this.data = res.data.data
      })
      this.$http.get('/user/getAllExpert').then((res)=>{
        this.expertList=res.data
        console.log(res.data)
      })
    },
    submit(){
      console.log(this.List)
      this.$http.post('/question/addExpert?list='+this.List+'&id='+this.data.id).then(()=>{
        this.display=false
        this.getData()
        this.$toast.add({severity: 'success', summary: '成功', life: 3000})
      })
    }

  }
}
</script>

<style scoped>

</style>